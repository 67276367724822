/**
 * @module
 * @name branding
 * @description : Handles branding related tasks such as logo replacement
 */

/**
 * @returns {string} endpoint base (with 'cdn' suffix instead of 'api')
 */
function _getVcoCdnEndpointBase() {
  return window.galen.cc.vco.config.apiEndpointBase.replace("-api.", "-cdn.");
}

/**
 * @param {string} logoElementId selector id of logo element
 * @returns {string} endpoint base (with 'cdn' suffix instead of 'api')
 */
function _setLogoSource(logoElementId) {
  const logo = document.getElementById(logoElementId);
  const vcoCdnEndpointBase = _getVcoCdnEndpointBase();
  const customerSubdomain = window.location.hostname.split(".").shift();
  logo.src = `${vcoCdnEndpointBase}cr/logos/${customerSubdomain}-login.png`; // login logo source
}

const branding = {
  setLogoSource: logoElementId => {
    _setLogoSource(logoElementId);
  },
};

export {branding};
