export enum ValidationRule {
  ANYTHING = "anything",
  EMAIL = "email",
  PHONE = "phone",
  REQUIRED = "required",
  SIGNIFICANT_LENGTH = "significantLength",
  FIVE_HUNDRED_CHARACTERS = "500characters",
  EMAIL_MATCH = "emailMatch",
  EXACT_MATCH = "exactMatch",
  VALID_PASSWORD = "validPassword",
  ONE_OR_MORE_CHECKED = "oneOrMoreChecked",
  NO_DISABLE_SELECTED = "noDisabledSelected",
  IS_IN_LIST = "isInList",
  RETURNS_TRUE = "returnsTrue",
  AT_LEAST_ONE_ITEM_IN_LIST = "atLeastOneItemInList",
}
