export enum SessionStorageItemKey {
  CONTEXT = "context",
  EXTERNAL_SOURCE_FILTER = "externalSourceFilter",
  IS_EXTERNAL_SSO_SESSION = "isExternalSsoSession",
  IS_DEFAULT_EXTERNAL_IDENTITY_PROVIDER = "isDefaultExternalIdentityProvider",
  IS_VCO_DIRECT_LOGIN_ENABLED = "isVcoDirectLoginEnabled",
  IS_PATIENT_CONTEXT_LOCKED = "isPatientContextLocked",
  IS_PATIENT_SEARCH_LOCKED = "isPatientSearchLocked",
  IS_SECURE_PATIENT = "isSecurePatient",
  PATIENT_ID = "patientId",
  INTENT = "intent",
  MOST_RECENTLY_VIEWED_PATIENT = "mostRecentlyViewedPatient",
}
