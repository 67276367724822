import {curry} from "./curry";

/**
 *
 * @param {string} className - default class for html element
 * @param {HTMLElement} element - html element to be modified
 * @returns {boolean}  - idempotent function
 */
function addClass(className, element) {
  const regex = new RegExp(className, "g");
  element.className = `${element.className.replace(regex, "").trim()} ${className}`;
  return true;
}

/**
 *
 * @param {string} className - default class for html element
 * @param {HTMLElement}  element - html element to be modified
 * @returns {boolean} - for indempotent-ness
 */
function removeClass(className, element) {
  const regex = new RegExp(className, "g");
  element.className = element.className.replace(regex, "").trim();
  return true;
}

/**
 * @anme addAttribute
 * @param {string} attribute - default class for html element
 * @param {string} value - value of the attribute
 * @param {HTMLElement} element - html element to be modified
 * @returns {boolean}  - idempotent function
 */
function addAttribute(attribute, value, element) {
  element.setAttribute(attribute, value);
  return true;
}

/**
 *
 * @param {string} attribute - default class for html element
 * @param {HTMLElement}  element - html element to be modified
 * @returns {boolean} - for indempotent-ness
 */
function removeAttribute(attribute, element) {
  element.removeAttribute(attribute);
  return true;
}

/**
 * show an element on the screen
 * @name show
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const show = curry(removeClass)("is-hidden");

/**
 * show an element on the screen
 * @name hide
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const hide = curry(addClass)("is-hidden");

/**
 * show an element on the screen that is already in the document flow
 * @name show
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const makeVisible = curry(addClass)("is-visible");

/**
 * hide an element on the screen but do not remove it from document flow
 * @name show
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const makeInvisible = curry(removeClass)("is-visible");

/**
 * remove error class from an element
 * @name removeErrorState
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const removeErrorState = curry(removeClass)("is-error");

/**
 * add error class to  an element
 * @name addErrorState
 * @param {HTMLElement} element - textbox, div , whatever
 * @returns {boolean} - idempotent function
 */
const addErrorState = curry(addClass)("is-error");

const disable = curry(addAttribute)("disabled")("disabled");

const enable = curry(removeAttribute)("disabled");

export {show, hide, removeErrorState, addErrorState, disable, enable, makeVisible, makeInvisible};
