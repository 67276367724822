/**
 * @module
 * @name setFocus
 * @description : Sets the focus of specified node on tab key press
 * Usage : Add the data attribute data-set-previous-focus="targetID" or data-set-next-focus="targetID" to a html element
 * ex : <button id="myButton">I get focus</button><input data-set-next-focus="myButton"/><input data-set-previous-focus="myButton"/>
 * @param {*} event - the event object (used to get keycode)
 * @returns {void}
 **/
function setFocus(event) {
  if (event.keyCode === 9) {
    // We only want to look for the tab key
    const activeElement = document.activeElement;

    const nextElementId = event.shiftKey
      ? activeElement.dataset?.setPreviousFocus // Going Backwards
      : activeElement.dataset?.setNextFocus; // Going Forwards

    const nextElement = document.getElementById(nextElementId);

    if (nextElement) {
      nextElement.focus();
    }
  }
}
document.body.addEventListener("keydown", setFocus);

// TODO: We don't use this anywhere except a single test in login-test.js. Should we start using it? Otherwise, we should just drop it altogether, since it's just bundle-bloat. -ER
