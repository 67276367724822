import {PasswordStrength} from "./password-strength";

/**
 *  checks a password against validity rules
 *  Rules :
 *      Can't contain the part of the user's email before the @
 *      Can't contain the part of the user's email after the @
 *      Can't contain the user's entire email
 * @param {string} password - password to validate
 * @param {string} email - email
 * @returns {boolean} isValid
 */
export function ruleCheck(password, email) {
  const isValid = !!email
    .split("@")
    .reduce((valid, part) => valid && password.indexOf(part) === -1, true);
  return isValid;
}

/**
 * @param {string} password - string be scored
 * @returns {number} - the score
 */
function charSetScore(password) {
  const numberRegx = /[\d]/g;
  const lowerCaseRegx = /[a-z]/g;
  const upperCaseRegx = /[A-Z]/g;
  const punctuationRegx = /[\W|_]/g;

  return (
    (numberRegx.test(password) ? 10 : 0) +
    (lowerCaseRegx.test(password) ? 26 : 0) +
    (upperCaseRegx.test(password) ? 26 : 0) +
    (punctuationRegx.test(password) ? 31 : 0)
  );
}

/**
 * @param {string} password - the password to check
 * @returns {{strength: null, bitsOfEntropy: number}} - analysis
 */
export function analyzePassword(password = "") {
  const info = {
    strength: PasswordStrength.UNKNOWN,
    bitsOfEntropy: 0,
  };
  const charSet = charSetScore(password);
  const result = window.Math.log(window.Math.pow(charSet, password.length)) / window.Math.log(2);

  switch (true) {
    case result <= 32:
      info.strength = PasswordStrength.WEAK;
      break;
    case result <= 64:
      info.strength = PasswordStrength.MEDIOCRE;
      break;
    case result <= 128:
      info.strength = PasswordStrength.OK;
      break;
    default:
      info.strength = PasswordStrength.GREAT;
  }

  info.bitsOfEntropy = Math.round(result);

  return info;
}
