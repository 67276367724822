import {serializeGetRequestParams} from "../libs/galen/sources/request-serializers";
import axios from "axios";

/**
 * @description data-layer for vco Authd
 * @param {object} config optional config object
 * @return {*} -
 * example config : {authUrl : "https://localhost:44311/session/token?ClientId=urn:galenhealthcare.com"}
 */
export class VcoAuthService {
  constructor(config) {
    this.vcoAuthUrl = `${config.apiEndpointBase}session/token?ClientId=${config.defaultClientId}`; //    TODO: this is undefined
    this.vcoDirectAuthUrl = `${config.apiEndpointBase}session/login?ClientId=${config.defaultClientId}`;
    this.customerLookupUrl = `${config.apiEndpointBase}webdomains?domain=${encodeURI(window.location.hostname)}`;
    this.customerPublicConfigEndpoint = `${config.apiEndpointBase}customer/public-config`;
    this.externalAuthenticationLookupUrl = `${config.apiEndpointBase}default-external-authentication/endpoints?domain=${encodeURI(window.location.hostname + (window.location.port ? `:${window.location.port}` : ""))}`;
    this.apiEndpointBase = config.apiEndpointBase;
  }

  /**
   * @name getVcoWebToken
   * @param {object} ccToken Valid CC Token
   * @param {string} customerId GUID of customer
   * @returns {*} ES6 Promise
   */
  getVcoWebToken = (ccToken, customerId) => {
    const url = `${this.vcoAuthUrl}${this.vcoAuthUrl.indexOf("?") > -1 ? "&" : "?"}CustomerId=${customerId}`;
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
      headers: {Authorization: `Bearer ${ccToken}`},
    };
    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then(({data}) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  getVcoWebTokenDirect = (username, password, customerId) => {
    const url = `${this.vcoDirectAuthUrl}${this.vcoDirectAuthUrl.indexOf("?") > -1 ? "&" : "?"}CustomerId=${customerId}`;
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
      headers: {
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
      },
    };
    return axios
      .get(url, config)
      .then(({data}) => {
        return data;
      })
      .catch(err => {
        throw err;
      });
  };

  /**
   * @name getCustomerId
   * @returns {*} ES6 Promise
   */
  getCustomerId = () => {
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
    };

    return new Promise((resolve, reject) => {
      axios
        .get(this.customerLookupUrl, config)
        .then(({data}) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  getCustomerPublicConfig = customerId => {
    const url = `${this.customerPublicConfigEndpoint}${this.customerPublicConfigEndpoint.indexOf("?") > -1 ? "&" : "?"}TargetCustomerId=${customerId}`;
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
    };
    return axios
      .get(url, config)
      .then(({data}) => {
        return data;
      })
      .catch(err => {
        throw err;
      });
  };

  /**
   * @name getExternalAuthenticationEndpoint
   * @returns {*} ES6 Promise
   */
  getExternalAuthenticationEndpoint = () => {
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
      headers: null,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(this.externalAuthenticationLookupUrl, config)
        .then(({data}) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
