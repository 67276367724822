/**
 * @name curry
 * @param {function} myFunction - the function to be curried with arity >= 2
 * @returns {Function} - the curried function
 */
function curry(myFunction) {
  if (typeof myFunction !== "function") {
    throw new Error("Cannot curry a non-function");
  }
  return function () {
    if (arguments.length < myFunction.length) {
      return curry(myFunction.bind(this, ...Array.from(arguments)));
    }
    return Reflect.apply(myFunction, this, Array.from(arguments));
  };
}

// Syntactic Sugar
curry.bindThis = newThis => curry.bind(newThis);

/**
 * Do a partial application to a function binding any variables
 * ex : const baz = partial(foo,'bar');
 *  Where foo is a function with arity >= 1.
 *  Returns a new function with foo's first parameter bound to the value 'bar'
 *
 * @param {function} fn function with arity >= 1, followed by any arguments to be bound to variables in order
 * @returns {Function} - partially applied function
 */
function partial(fn) {
  // Convert arguments object to an array, removing the first argument.
  const boundArgs = Array.from(arguments).slice(1);
  const boundFn = fn.bind(this, ...boundArgs);
  return function () {
    return boundFn(...arguments);
  };
}

export {curry, partial};
