/**
 * @module login-form-validation
 * Our errors cache is not sophisticated, therefore
 * this validator only works if there is one form on the page.
 */
import {formFieldValidator} from "./libs/form-field-validator";
import {curry} from "./libs/curry";
import {show, hide, addErrorState, removeErrorState} from "./libs/html-element-manipulators";

let errors = new WeakMap();
let controls = []; // we do this because there's no good way to get the keys from the WeakMap

/**
 * @returns {Array|*} error messages
 */
function getErrors() {
  return controls.reduce(
    (errs, key) => (!!errors.get(key) ? errs.concat(errors.get(key)) : errs),
    []
  );
}

/**
 * calls the validator and sends results to callback.
 * @name validateField
 * @param {Array | string} rules - a key to the rule or rules on which to validated.
 * @param {function} callback - callback function is passed validation object.
 * @param {HTMLElement} control - a form control node.
 * @param {object} event - a DOM event object.
 * @returns {function | object} -  a Partial function or formFieldValidation object.
 */
function _validateField(rules, callback, control, event) {
  const validation = formFieldValidator(rules, event.target.value);
  Reflect.apply(callback, this, [validation, control]);
  return validation;
}

/**
 * Side Effects for setting error status
 * @param {string[]} messages - An array of error message strings
 * @param {HTMLElement} control DOM element to receive is-error class
 * @param {HTMLElement} errorBanner DOM element where errors are displayed
 * @returns {boolean} - always true for idempotent-ness
 */
function setErrorStatus(messages, control, errorBanner) {
  const uniqueMessages = Array.from(new Set(messages));
  if (control) {
    if (errors.get(control).length > 0) {
      if (!(document.activeElement === control)) {
        // our control has lost focus, it's okay to add an error warning.
        addErrorState(control);
        errorBanner.innerHTML = uniqueMessages.reduce(
          (spans, message) => `${spans}<span>${message}</span>`,
          ""
        );
      }
      // Do nothing if we see an error but our control still has focus
    } else {
      // the error was fixed, let's remove the warnings immediately
      errorBanner.innerHTML = uniqueMessages.reduce(
        (spans, message) => `${spans}<span>${message}</span>`,
        ""
      );
      removeErrorState(control);
    }
  }
  if (uniqueMessages.length === 0) {
    hide(errorBanner);
  } else {
    if (!(document.activeElement === control)) {
      show(errorBanner);
    }
  }
  return true;
}

/**
 * @returns {boolean} true if error exist
 * @private
 */
function _hasError() {
  return getErrors.length > 0;
}

/**
 * callback for username validation
 * @param {HTMLElement} errorBanner DOM element where errors are displayed
 * @param {object} validation -validation object
 * @param {HTMLElement} control DOM element to receive is-error class
 * @returns {boolean} - indicates validation status
 * @private
 */
function _handleValidation(errorBanner, validation, control) {
  errors.set(control, validation.errorMessages);
  setErrorStatus(getErrors(), control, errorBanner);
  return true;
}

/**
 * @name loginFormValidation
 * @param {object[]} validations -  controls and their validations
 * @param {HTMLElement} errorBanner  - DOM element where errors are displayed
 * @return {object} - some validation methods
 */
export function loginFormValidation(validations, errorBanner) {
  const validateField = curry(_validateField);
  const handleValidation = curry(_handleValidation)(errorBanner);
  // Reset our caches
  errors = new WeakMap();
  controls = [];
  // TODO : recursion instead of forEach would be more functional. (JN)
  validations.forEach(validation => {
    const myRules = Array.isArray(validation.rules) ? validation.rules : [validation.rules];
    const validationHandler = validateField(myRules, handleValidation, validation.control);
    const eventName = validation.eventName || "blur";
    controls.push(validation.control);
    validation.control.addEventListener(eventName, validationHandler);
  });
  return {hasError: _hasError, addRule: formFieldValidator.addRule};
}
