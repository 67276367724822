/**
 * @module
 * @name login
 * @description : handles login form authentication and form validation.
 */
import "./libs/polyfill-classList";
import {tokenManager} from "./auth/token-manager";
import {galenLogger} from "./libs/galen-logger";
import {branding} from "./libs/branding";
import {VcoAuthService} from "./auth/vco-auth-service";
import {CcAuthService} from "./auth/cc-auth-service";
import "./libs/set-focus";
import {loginFormValidation} from "./login-form-validation";
import {show, hide} from "./libs/html-element-manipulators";
import {SessionTokenKey} from "./auth/session-token-key";
import {finalizeAndLoadGalenAppInsights} from "./libs/app-insights/galen-app-insights";
import {sessionStorage} from "./libs/web-storage";
import {SessionStorageItemKey} from "./auth/session-storage-item-key";

const ccDataSource = new CcAuthService(window.galen.cc.vco.config);
const errorBanner = document.getElementById("errorBanner");
const genericLoginErrorMessage =
  "An unexpected error occurred while logging in, please contact your administrator."; // TODO: This needs to be put into a configuration namespace
const firstElement = document.getElementById("mainNavButton");
const forwardUrl = "/";
const genericErrorMessage = "An error occurred.  Please try again later."; // TODO: This needs to be put into a configuration namespace
const loginForm = document.getElementById("loginForm");
const loginLoadingSpinner = document.getElementById("loginLoadingSpinner");
const externalAuthContainer = document.getElementById("externalAuthLoginContainer");
const password = document.getElementById("password");
const username = document.getElementById("username");

const vcoAuthService = new VcoAuthService(window.galen.cc.vco.config);

// Initialize App Insights. No extensions currently.
finalizeAndLoadGalenAppInsights();

// TODO : move these two constants into config namespace.
export const ccAuthenticationFailureResponses = {
  inactive: "This account has been deactivated. Please contact your administrator.",
  lockedout:
    "This account has been locked out for too many failed login attempts. Please contact your administrator.",
  lockoutwarning:
    "Invalid username or password. Lock out will occur with {remainingAttempts} more failed attempts.",
  passwordexpired:
    'Your password has expired. <a href="./login/forgot-password">Please request a password reset.</a>',
  unknown: "Invalid username or password.",
};
export const vcoAuthorizationFailureResponses = {
  genericerror: genericLoginErrorMessage,
  userinactive: "This user has been deactivated. Please contact your administrator.",
  loginnotallowed: "Direct login is not allowed. Please login via SSO.",
  ipaddressrestricted: "Client ip address is restricted. Please contact your administrator.",
  unknown: "Invalid username or password.",
};

/**
 *
 * @param {string} myUsername - username
 * @param {string} myPassword - password
 * @returns {Promise} Resolves to JWT
 */
function getCCToken(myUsername, myPassword) {
  return new Promise((resolve, reject) => {
    ccDataSource
      .getCcWebToken(myUsername, myPassword)
      .then(resp => {
        if (!!resp.result && resp.result.toLowerCase() === "valid") {
          tokenManager.addToken(resp.token, SessionTokenKey.CONNECTED_CARE);
          resolve(resp.token);
        } else {
          reject(new Error("Invalid CC Token"));
        }
      })
      .catch(err => {
        reject(err);
        show(errorBanner);
        hide(loginLoadingSpinner);
      });
  });
}

/**
 * @returns {Promise} Promise resolves to token
 */
function getCustomerId() {
  return new Promise((resolve, reject) => {
    vcoAuthService
      .getCustomerId()
      .then(resp => {
        resolve(resp.customerId);
      })
      .catch(err => {
        galenLogger.logError(err);
        reject(err);
      });
  });
}

/**
 * @returns {Promise} Promise resolved to external auth endpoint
 */
function getExternalAuthenticationEndpoint() {
  return new Promise((resolve, reject) => {
    vcoAuthService
      .getExternalAuthenticationEndpoint()
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        galenLogger.logError(err);
        reject(err);
      });
  });
}

/**
 * @param {Token} ccToken - JSON Web Token object
 * @param {string} customerId - GUID for customer
 * @returns {Promise} Promise resolves to token
 */
function getVCOToken(ccToken, customerId) {
  return new Promise((resolve, reject) => {
    vcoAuthService
      .getVcoWebToken(ccToken, customerId)
      .then(resp => {
        if (!!resp.result && resp.result.toLowerCase() === "valid") {
          if (tokenManager.addToken(resp.token, SessionTokenKey.VCO)) {
            resolve(resp.token);
          } else {
            const err = new Error("VCO Token failed local storage save");
            galenLogger.logError(err);
            reject(err);
          }
        } else {
          const err = new Error("Invalid VCO Token");
          galenLogger.logError(err);
          reject(err);
        }
      })
      .catch(err => {
        galenLogger.logError(err);
        reject(err);
      });
  });
}

/**
 * @name getVCOTokenDirect
 *
 * @param {string} directVcoUsername - username@description Handles login
 * @param {string} directVcoPassword - password
 * @param {string} customerId - GUID for customer
 * @returns {Promise} Promise resolves to vco token
 */
function getVCOTokenDirect(directVcoUsername, directVcoPassword, customerId) {
  return vcoAuthService
    .getVcoWebTokenDirect(directVcoUsername, directVcoPassword, customerId)
    .then(resp => {
      if (!!resp.result && resp.result.toLowerCase() === "valid") {
        if (tokenManager.addToken(resp.token, SessionTokenKey.VCO)) {
          return resp.token;
        } else {
          throw new Error("VCO Token failed local storage save");
        }
      } else {
        throw new Error("Invalid VCO Token");
      }
    })
    .catch(err => {
      galenLogger.logError(err);
      throw err;
    });
}

/**
 * @name login
 *
 * @param {string} myUsername - username@description Handles login
 * @param {string} myPassword - password
 * @returns {boolean} if both exist
 */
function login(myUsername, myPassword) {
  hide(errorBanner);
  show(loginLoadingSpinner);

  /**
   * @name getVcoAuthorizationError
   * @param {object} err - An error object with a response that can be parsed into JSON
   * @param {object} failureResponses - A collection of responses for reasons why authorization failed
   * @returns {string} An authorization failure message to display to users
   */
  function getVcoAuthorizationFailureResponse(err, failureResponses) {
    const response = !!err ? err.response : null;
    const data = !!response ? response.data : null;
    const result =
      response === null || response.status === 0 || response.status === 500
        ? "genericError"
        : data.result || "";
    const msg = failureResponses[result.toLowerCase()];
    return !!msg ? msg : genericLoginErrorMessage;
  }

  /**
   * @name vcoLogin
   * @param {object} ccToken Connected Care Token
   * @returns {Promise<T>|Promise.<T>} Promise
   */
  function vcoLoginWithCcToken(ccToken) {
    return getCustomerId()
      .then(resp => {
        getVCOToken(ccToken, resp)
          .then(() => {
            window.location = forwardUrl;
          })
          .catch(err => {
            galenLogger.logError(err);
            errorBanner.innerHTML = getVcoAuthorizationFailureResponse(
              err,
              vcoAuthorizationFailureResponses
            );
            show(errorBanner);
            hide(loginLoadingSpinner);
          });
      })
      .catch(err => {
        galenLogger.logError(err);
        // Something's wrong with our cc token
        errorBanner.innerHTML = genericErrorMessage;
        show(errorBanner);
        hide(loginLoadingSpinner);
      });
  }

  /**
   * @name vcoDirectLogin
   * @param {string} directUsername - username@description Handles login
   * @param {string} directPassword - password
   * @returns {Promise<T>|Promise.<T>} Promise
   */
  function vcoDirectLogin(directUsername, directPassword) {
    return getCustomerId()
      .then(resp => {
        getVCOTokenDirect(directUsername, directPassword, resp)
          .then(() => {
            window.location = forwardUrl;
          })
          .catch(err => {
            galenLogger.logError(err);
            errorBanner.innerHTML = getCcAuthenticationFailureResponse(
              err,
              ccAuthenticationFailureResponses
            );
            show(errorBanner);
            hide(loginLoadingSpinner);
          });
      })
      .catch(err => {
        galenLogger.logError(err);
        errorBanner.innerHTML = genericErrorMessage;
        show(errorBanner);
        hide(loginLoadingSpinner);
      });
  }

  /**
   * @name getCcAuthenticationError
   * @param {object} err - An error object with a response that can be parsed into JSON
   * @param {object} failureResponses - A collection of responses for reasons why authentication failed
   * @returns {string} An authentication failure message to display to users
   */
  function getCcAuthenticationFailureResponse(err, failureResponses) {
    const response = !!err ? err.response : null;
    const data = !!response ? response.data : null;
    let remainingAttempts = null;
    let failureReason = null;
    let msg;

    if (data && (data.remainingAttempts || data.remainingAttempts === 0)) {
      remainingAttempts = data.remainingAttempts.toString();
    } else {
      remainingAttempts = "Unknown";
    }

    if (data && data.reason) {
      failureReason = data.reason.toLowerCase();
    }

    if (failureReason !== null && failureReason in failureResponses) {
      msg = failureResponses[failureReason];
    } else {
      msg = genericLoginErrorMessage;
    }

    // if the message template can handle a remainingAttempts value, use it
    msg = msg.replace("{remainingAttempts}", remainingAttempts);

    return msg;
  }

  tokenManager.clearTokens();

  // Determine if VCO direct login or CC then VCO login
  getCustomerId()
    .then(customerIdResponse => {
      vcoAuthService.getCustomerPublicConfig(customerIdResponse).then(customerConfigResponse => {
        sessionStorage.setItem(
          SessionStorageItemKey.IS_VCO_DIRECT_LOGIN_ENABLED,
          customerConfigResponse.isVcoDirectLoginEndpointEnabled.toString()
        );

        if (customerConfigResponse.isVcoDirectLoginEndpointEnabled) {
          // Do login using VCO endpoint instead.
          vcoDirectLogin(myUsername, myPassword).catch(err => {
            galenLogger.logError(err);
            errorBanner.innerHTML = getCcAuthenticationFailureResponse(
              err,
              ccAuthenticationFailureResponses
            );
            show(errorBanner);
            hide(loginLoadingSpinner);
          });
        } else {
          getCCToken(myUsername, myPassword)
            .then(vcoLoginWithCcToken)
            .catch(err => {
              galenLogger.logError(err);
              errorBanner.innerHTML = getCcAuthenticationFailureResponse(
                err,
                ccAuthenticationFailureResponses
              );
              show(errorBanner);
              hide(loginLoadingSpinner);
            });
        }
      });
    })
    .catch(err => {
      galenLogger.logError(err);
      errorBanner.innerHTML = genericErrorMessage;
      show(errorBanner);
      hide(loginLoadingSpinner);
    });
}

branding.setLogoSource("logo");

// Set initial focus
if (firstElement) {
  firstElement.focus();
}

// Login Setup
const validator = loginFormValidation(
  [
    {
      control: username,
      eventName: "blur",
      rules: ["email"],
    },
    {
      control: password,
      eventName: "blur",
      rules: ["required"],
    },
    {
      control: username,
      eventName: "keyup",
      rules: ["email"],
    },
    {
      control: password,
      eventName: "keyup",
      rules: ["required"],
    },
  ],
  errorBanner
);

loginForm.addEventListener("submit", event => {
  event.preventDefault();
  if (!validator.hasError()) {
    errorBanner.innerHTML = "";
    login(username.value, password.value);
  }
  return false;
});

getExternalAuthenticationEndpoint()
  .then(resp => {
    if (!!resp && !!resp.results) {
      resp.results.forEach((result, index) => {
        if (!result) {
          return;
        }

        const providerName = document.createTextNode(
          `Log in with ${result.externalIdentityProviderName}`
        );

        const link = document.createElement("a");
        link.href = result.endpoint;
        link.tabIndex = index + 300;
        link.appendChild(providerName);

        externalAuthContainer.appendChild(link);
      });
      show(externalAuthContainer);
    } else {
      hide(externalAuthContainer);
    }
  })
  .catch(err => {
    galenLogger.logError(err);
    hide(externalAuthContainer);
  });
