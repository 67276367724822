import events from "events";
import {galenLogger} from "../libs/galen-logger";
import {sessionStorage} from "../libs/web-storage";
import {Token, isValidToken, TokenEventConstants, TokenEventCallback} from "./token";
import {Map} from "immutable";
import {safeParseJson} from "../vco/tools/json-tools";
import {UnsafePojo} from "../libs/galen/objects/base-types";
import {SessionTokenKey} from "./session-token-key";

let _tokenCache: Map<string, Token> = Map<string, Token>();

// Using WeakMaps for caches with keys
// We can do this thanks to Liskov
let _tokenListeners = Map<Token, TokenEventCallback>();

/**
 * @name _removeTokenFromCache
 * @description deletes token from cache but NOT local storage
 * @param {string} tokenKey - token key
 * @returns {boolean} true if the token was deleted
 */
function _removeTokenFromCache(tokenKey: string) {
  const token = _tokenCache.get(tokenKey);

  if (!token) {
    return _tokenCache;
  }

  const listener = _tokenListeners.get(token);

  if (!!listener) {
    token.removeEventListener(listener);
  }

  token.destroy();
  return Reflect.deleteProperty(_tokenCache, tokenKey);
}

export type SessionStorageRawToken = {
  key: SessionTokenKey;
  token: string;
};

/**
 * @name _getSessionStoredRawTokens
 * @descriptions returns token caches from local storage
 * @param {string} sessionStorageKey - key for session
 * @returns {any} - token cache object
 */
function _getSessionStoredRawTokens(sessionStorageKey: string) {
  const rawTokens = safeParseJson(sessionStorage?.getItem(sessionStorageKey));
  if (Array.isArray(rawTokens)) {
    return rawTokens as SessionStorageRawToken[];
  }

  return null;
}

/**
 * @name _saveCache
 * @description saves current token cache to local storage
 * @param {string} sessionStorageKey - key for session
 * @returns {void}
 */
function _saveCache(sessionStorageKey: string) {
  const tokens = _tokenCache
    .keySeq()
    .map(key => ({key, token: _tokenCache.get(key)?.rawToken}))
    .toArray();
  if (sessionStorage) {
    // not available during testing
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(tokens));
  } else {
    galenLogger.logError("Session Storage is not available, Cannot store token");
  }
}

/**
 * @name _deleteToken
 * @description deletes token from local storage
 * @param {string} tokenKey - token key
 * @param {string} sessionStorageKey - key for session
 * @returns {boolean} didDelete - true if the token was deleted
 */
function _deleteToken(tokenKey: string, sessionStorageKey: string) {
  const token = _tokenCache.get(tokenKey);

  if (!token) {
    return false;
  }

  const listener = _tokenListeners.get(token);

  if (!!listener) {
    _tokenListeners = _tokenListeners.delete(token);
    token.removeEventListener(listener);
  }

  token.destroy();

  _tokenCache = _tokenCache.remove(tokenKey);
  _saveCache(sessionStorageKey);
  return !!token; // if it existed, we deleted it
}

const createTokenEventHandler = (tokenManager: TokenManager, key: string): TokenEventCallback => {
  return (arg: UnsafePojo) => {
    const payload = Object.assign({}, arg) as UnsafePojo;
    payload.tokenKey = key;
    tokenManager.emit(arg.action as string, key, payload);
  };
};

class TokenManager extends events.EventEmitter {
  private _setTimeoutWatch: boolean;
  private _sessionStorageKey: string;

  constructor(sessionStorageKey: string, setTimeoutWatch: boolean = true) {
    super();
    this._setTimeoutWatch = setTimeoutWatch;
    this._sessionStorageKey = sessionStorageKey;
    this.removeToken = this.removeToken.bind(this);
    this.addToken = this.addToken.bind(this);
    this.getToken = this.getToken.bind(this);
    this.clearTokens = this.clearTokens.bind(this);
    this.addEventListener = this.addEventListener.bind(this);
    this.removeEventListener = this.removeEventListener.bind(this);

    const rawTokens = _getSessionStoredRawTokens(sessionStorageKey);

    // TODO merge or recursion, don't loop (JN)
    rawTokens?.forEach(token => {
      this.addToken(token.token, token.key);
    });
  }

  /**
   * @name addToken
   * @param {*} rawToken - base64 encoded JWT string
   * @param {string} tokenKey - name of token
   * @returns {boolean} - true if token was successfully added
   */
  addToken(rawToken: string, tokenKey: string) {
    if (!isValidToken(rawToken)) {
      return false;
    }

    if (_tokenCache.get(tokenKey)) {
      this.removeToken(tokenKey, true);
    }
    const newToken = new Token(rawToken, this._setTimeoutWatch);
    _tokenCache = _tokenCache.set(tokenKey, newToken);
    if (this._setTimeoutWatch) {
      const token = _tokenCache.get(tokenKey);
      // since we use this listener from two places that emit events for the same listener,
      // we need use a factory method taking in "this" to ensure
      // we have no this-related bugs.
      const listener = createTokenEventHandler(this, tokenKey);

      if (!!token) {
        _tokenListeners = _tokenListeners.set(token, listener);
        token.addEventListener(listener);
      }
    }
    _saveCache(this._sessionStorageKey);
    this.emit(TokenEventConstants.TOKEN_CREATED, tokenKey, _tokenCache.get(tokenKey));
    return true;
  }

  getToken(tokenKey: string) {
    const token = _tokenCache.get(tokenKey) ?? null;
    return token;
  }

  getTokens() {
    const tokens = _tokenCache
      .keySeq()
      .map(key => _tokenCache.get(key))
      .toArray();
    return tokens;
  }

  /**
   * @name removeToken
   * @param {string} tokenKey - name of token
   * @param {boolean} silence - true turns off event emission
   * @returns {boolean} - returns false if token is not in cache
   */
  removeToken(tokenKey: string, silence: boolean = false) {
    const didDelete = _deleteToken(tokenKey, this._sessionStorageKey);
    const me = this; // eslint-disable-line @typescript-eslint/no-this-alias

    /**
     * @name emitEvent
     * @param {*} key - key (?)
     * @returns {function} takes key, destroys (?)
     */
    function emitEvent(key: string) {
      const payload = {
        tokenKey: key,
        action: TokenEventConstants.TOKEN_DESTROYED,
      };
      me.emit(TokenEventConstants.TOKEN_DESTROYED, key, payload);
    }

    if (didDelete && !silence) {
      emitEvent(tokenKey);
    }

    return didDelete;
  }

  /**
   * @name clearTokens
   * @description removes all tokens from local storage and cache
   * @returns {boolean} - True if tokens were found and removed from local storage
   */
  clearTokens() {
    const me = this; // eslint-disable-line @typescript-eslint/no-this-alias
    const deletedKeys = _tokenCache.keySeq().toArray();

    const hadTokens = !!sessionStorage?.getItem(this._sessionStorageKey) || deletedKeys.length > 0;
    sessionStorage?.removeItem(this._sessionStorageKey);

    deletedKeys.forEach((key: string) => {
      _deleteToken(key, me._sessionStorageKey); // we need to remove listeners
    });

    _tokenCache = Map<string, Token>(); // just in case a deletion failed;
    if (deletedKeys.length > 0) {
      const payload = {
        action: TokenEventConstants.TOKENS_CLEARED,
        keys: deletedKeys,
      };
      me.emit(TokenEventConstants.TOKENS_CLEARED, deletedKeys, payload);
    }

    return hadTokens;
  }

  addEventListener(event: TokenEventConstants, callback: TokenEventCallback) {
    this.on(event, callback);
  }

  removeEventListener(event: TokenEventConstants, callback: TokenEventCallback) {
    this.removeListener(event, callback);
  }

  destroy() {
    Object.keys(_tokenCache).forEach(key => _removeTokenFromCache(key));
  }
}

export const tokenManager = new TokenManager(
  "galenTokens",
  !window.galen.cc.vco.config.blockSessionPolling
);
