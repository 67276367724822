import {analyzePassword} from "./password-strength-manager";
import {ValidationRule} from "./validation-rule";
import {PasswordStrength} from "./password-strength";

// Our default rules
const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

// This is the regex used by the server but sadly unicode regex support is not available in IE.
// const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|
// [!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x
// 09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\
// x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)
// ))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\
// d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[
// \u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|
// [\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
const phoneRegex = /^(\+?\d{1,2}\s?)?[\s.\-+]?\(?\d{3}\)?[\s.\-+]?\d{3}[\s.\-+]?\d{4}$/;

export const validationRules = [
  {
    name: ValidationRule.ANYTHING,
    validator: () => true,
    errorMessage: "",
  },
  {
    name: ValidationRule.EMAIL,
    validator: value => emailRegex.test(value),
    errorMessage: "Invalid Email",
  },
  {
    name: ValidationRule.PHONE,
    validator: value => phoneRegex.test(value),
    errorMessage: "Invalid Phone Number",
  },
  {
    name: ValidationRule.REQUIRED,
    validator: value =>
      (typeof value === "string" && value.length > 0) ||
      (typeof value === "number" && !isNaN(value)),
    errorMessage: "Field cannot be blank",
  },
  {
    name: ValidationRule.SIGNIFICANT_LENGTH,
    validator: value =>
      (typeof value === "string" && value.length > 2) ||
      (typeof value === "number" && !isNaN(value)),
    errorMessage: "Field must be of significant length",
  },
  {
    name: ValidationRule.FIVE_HUNDRED_CHARACTERS,
    validator: value => typeof value === "string" && value.length <= 500,
    errorMessage: "Field must be 500 characters or fewer in length",
  },
  {
    name: ValidationRule.EMAIL_MATCH,
    validator: (email1, email2) =>
      emailRegex.test(email1) &&
      emailRegex.test(email2) &&
      email1.toLowerCase().trim() === email2.toLowerCase().trim(),
    errorMessage: "Emails do not match",
  },
  {
    name: ValidationRule.EXACT_MATCH,
    validator: (string1, string2) => string1 === string2,
    errorMessage: "Values do not match",
  },
  {
    name: ValidationRule.VALID_PASSWORD,
    validator: password =>
      [PasswordStrength.OK, PasswordStrength.GREAT].indexOf(analyzePassword(password).strength) !==
      -1,
    errorMessage: "Your password is too weak",
  },
  {
    name: ValidationRule.ONE_OR_MORE_CHECKED,
    validator: boxes => boxes.reduce((checked, box) => checked || box.checked, false),
    errorMessage: "Check at least one option",
  },
  {
    name: ValidationRule.NO_DISABLE_SELECTED,
    validator: boxes =>
      boxes.reduce((checked, box) => checked && !(box.checked && box.disabled), true),
    errorMessage: "Please choose a valid option",
  },
  {
    name: ValidationRule.IS_IN_LIST,
    validator: (value, list) => (Array.isArray(list) ? list : []).indexOf(value) > -1,
    errorMessage: "Invalid selection",
  },
  {
    name: ValidationRule.RETURNS_TRUE,
    validator: (value, func) => func(value),
    errorMessage: "Invalid selection",
  },
  {
    name: ValidationRule.AT_LEAST_ONE_ITEM_IN_LIST,
    validator: arr => Array.isArray(arr) && arr.length > 0,
    errorMessage: "Invalid selection",
  },
];
