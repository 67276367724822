import {tokenManager} from "./token-manager";
import {serializeGetRequestParams} from "../libs/galen/sources/request-serializers";
import axios from "axios";
import {SessionTokenKey} from "./session-token-key";

/**
 * @description data-layer for CC Authd
 * @parameter {*} config
 * @return {*} -
 * example config : {authUrl : "https://localhost:44311/session/token?ClientId=urn:galenhealthcare.com"}
 */
export class CcAuthService {
  // config is optional
  constructor(config) {
    this.ccAuthUrl = `${config.connectedCareApiEndpointBase}session/token?ClientId=${config.defaultConnectedCareClientId}`;
    this.apiEndpointBase = config.connectedCareApiEndpointBase;
  }

  /**
   * @name getCcWebToken
   * @param {string} username - user's username
   * @param {string} password - user's password
   * @returns {Promise<T>|Promise} Promise
   */
  getCcWebToken = (username, password) => {
    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
      headers: {
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(this.ccAuthUrl, config)
        .then(({data}) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  getCurrentCcWebTokenDetails = () => tokenManager.getToken(SessionTokenKey.CONNECTED_CARE);

  getRefreshedCcWebToken = () => {
    const tokenDetails = this.getCurrentCcWebTokenDetails();

    if (tokenDetails === null) {
      return Promise.reject("Unable to refresh session. Login has expired");
    }

    const url = `${this.apiEndpointBase}session/refreshtoken`;

    const config = {
      params: null,
      paramsSerializer: serializeGetRequestParams,
      headers: {Authorization: `Bearer  ${tokenDetails.rawToken}`},
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then(({data}) => {
          if (!!data.result && data.result.toLowerCase() === "valid") {
            resolve(data.token);
          }
          reject(new Error("Invalid CC Token"));
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
