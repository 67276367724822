import {galenLogger} from "../../libs/galen-logger";

export const safeParseJson = (jsonString: unknown): unknown => {
  let result = null;
  try {
    if (!!jsonString && typeof jsonString === "string" && jsonString.length > 0) {
      result = JSON.parse(jsonString);
    }
  } catch (e) {
    if (e.message) {
      e.message = `Failed to parse custom JSON: ${e.message}`;
    }
    galenLogger.logError(e);
  }
  return result;
};
