/**
 * @module formFieldValidator
 * DEPRECATED!! DO NOT USE!!!!!!!!!!!!!!!!!!!!!!!
 * A library for validating form field values.
 */

import {curry} from "./curry";
import {validationRules} from "./validation-rules";
import {galenLogger} from "./galen-logger";

const ruleCache = {};

/**
 * @name validationRuleFactory
 * @param {function} rule - a function that returns a boolean
 * @param {string} errorMessage - a string describing a failed validation
 * @returns {{rule: *, errorMessage: *}} - a validation rule object.
 */
function validationRuleFactory(rule, errorMessage) {
  return {rule, errorMessage};
}

/**
 * @name validator
 * @param {Array | string} rules - key(s) of validation rules
 * @param {*} value - value to validate
 * additional values may be appended if required by a rule for validation
 * @returns {function | object} curried function or validation object.
 */
function validator(rules, value) {
  return (Array.isArray(rules) ? rules : [rules]).reduce(
    (validationObj, rule) => {
      if (!ruleCache[rule]) {
        galenLogger(
          new Error(
            `Validation rule "${rule}" is not in the validation rule cache. skipping validation for this control`
          )
        );
        return {
          isValid: true,
          errorMessages: validationObj.errorMessages,
        };
      }
      const isValid = ruleCache[rule].rule(value, ...Array.from(arguments).slice(2));
      return {
        isValid: isValid && validationObj.isValid,
        errorMessages: isValid
          ? validationObj.errorMessages
          : validationObj.errorMessages.concat(ruleCache[rule].errorMessage),
      };
    },
    {isValid: true, errorMessages: []}
  );
}

const formFieldValidator = curry(validator);

formFieldValidator.addRule = (name, rule, errorMessage) => {
  ruleCache[name] = validationRuleFactory(rule, errorMessage);
  return formFieldValidator(name);
};

// TODO: this would be more functional with recursion
validationRules.forEach(rule =>
  formFieldValidator.addRule(rule.name, rule.validator, rule.errorMessage)
);

export {formFieldValidator};
